<template>
  <div>
    <div class="card">
      <div class="card-body form-card">
        <div class="row search-box">

          <div class="col-12 col-md-4">
            <el-autocomplete
              class="full-width"
              v-model="race_selected"
              :fetch-suggestions="querySearch"
              placeholder="Chọn sự kiện"
              @select="handleSelect"
            >
              <template slot-scope="{ item }">
                <div class="item">{{ item.id + '. ' + item.title }}</div>
              </template>
            </el-autocomplete>

          </div>

          <div class="col-12 col-md-4">
            <el-input v-model="form.user_id" placeholder="User ID/Email/SĐT" @keyup.enter.native="getUserOrder"></el-input>
          </div>

          <div class="col-12 col-md-4">
            <el-button class="full-width" type="primary" icon="el-icon-info" @click="getUserOrder">
              Xem đơn hàng
            </el-button>
          </div>

        </div>

      </div>
    </div>

    <div class="card" v-if="'order_id' in order">
      <div class="card-body form-card">

        <div class="row">
          <div class="col-12">
            <table class="table table-bordered table-content">

              <tr>
                <td class="d-none d-md-block">Khách hàng</td>
                <td>
                  <div class="d-block d-md-none title-mobile">Khách hàng</div>
                  <div>
                    <a class="red" :href="'https://irace.vn/races/users/' + order.user_id" target="_blank"><b>{{ order.user_id }}</b></a>
                    - {{ order.name }} - {{ order.email }} - {{ order.phone }}
                  </div>
                  <div>{{ order.address + ', ' + order.ward + ', ' + order.district + ', ' }}
                    <b class="vip">{{ order.province }}</b>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="d-none d-md-block">Đơn hàng</td>
                <td>
                  <div class="d-block d-md-none title-mobile">Đơn hàng</div>
                  Mã đơn hàng: <b class="red">{{ order.order_id }}</b> - Tổng thanh toán: <b style="font-size: 16px;">{{ $util.formatMoney(order.total_payment) }}</b>
                  <span v-if="order.order_discount"> - Giảm giá: <b>{{ $util.formatMoney(order.order_discount) }}</b></span> <span v-if="order.coupon_code"> - Mã giảm giá: <el-tag type="success" size="small">{{ order.coupon_code }}</el-tag></span> <span v-if="order.donate"> - Đóng góp: <b>{{ $util.formatMoney(order.donate) }}</b></span>
                  <div v-if="order.badge_name">Khắc tên: <b class="vip">{{ order.badge_name }}</b></div>
                </td>
              </tr>

              <tr>
                <td class="d-none d-md-block">Tiện ích</td>
                <td>
                  <div class="d-block d-md-none title-mobile">Tiện ích</div>
                  <div v-if="!order.addons.length">Không có</div>
                  <div v-else>
                    <div v-for="addon in order.addons">
                      <span><span v-if="order.addons.length > 1"> - </span><b class="vip">{{ addon.title }}</b></span>
                      <span v-if="addon.variant_title && addon.variant_title != 'Default'"> - Phiên bản: <b class="pro">{{ addon.variant_title }}</b></span>
                      <span v-if="addon.engraved_name"> - Khắc tên: <b class="pro">{{ addon.engraved_name }}</b></span>
                      <span> - Giá: {{ $util.formatMoney(addon.price) }}</span>
                    </div>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="d-none d-md-block">Thống kê</td>
                <td>
                  <div class="d-block d-md-none title-mobile">Thống kê</div>
                  <div>- Thứ tự thanh toán: <b class="red">{{ order.index_order }}</b>/{{ order.total_order }}</div>
                  <div v-if="order.index_completed > 0">- Thứ tự hoàn thành: <b class="red">{{ order.index_completed }}</b>/{{ order.total_completed }}</div>
                  <div v-else>- Thứ tự hoàn thành: <b class="vip">Chưa hoàn thành sự kiện</b></div>
                  <div v-if="order.gift">- Quà tặng: <b class="vip">{{ order.gift }}</b></div>
                </td>
              </tr>

              <tr>
                <td class="d-none d-md-block">Nhận hàng</td>
                <td>
                  <div class="d-block d-md-none title-mobile">Nhận hàng</div>
                  <div v-if="order.location_id"><b class="vip">{{ order.location_name }}</b></div>
                  <div v-else><b class="vip">TẠI NHÀ</b> ({{ order.full_address }})</div>
                </td>
              </tr>

              <tr>
                <td class="d-none d-md-block">Vận chuyển</td>
                <td>
                  <div class="d-block d-md-none title-mobile">Vận chuyển</div>
                  <p v-if="order.meta && order.meta.transport">
                    <b v-if="order.meta.transport.name">{{ order.meta.transport.name }}</b>
                    <b v-else>{{ shipping_status[form.shipping_status] }}</b>
                    <b v-if="order.meta.transport.shipping_code"> - {{ order.meta.transport.shipping_code }}</b>
                    <b v-if="order.meta.shipping_at"> - {{ $util.dateTimeFormat(order.meta.shipping_at) }}</b>
                  </p>

                  <div class="row shipping">
                    <div class="col-12 col-md-3">
                      <el-select class="full-width" clearable placeholder="Trạng thái" v-model="form.shipping_status">
                        <el-option v-for="(value, key) in shipping_status" :key="key" :label="value" :value="key"></el-option>
                      </el-select>
                    </div>
                    <div class="col-12 col-md-3">
                      <el-date-picker
                        v-model="form.shipping_at"
                        type="date"
                        placeholder="Chọn ngày">
                      </el-date-picker>
                    </div>
                    <div class="col-12 col-md-3">
                      <el-select class="full-width" clearable placeholder="Nhà vận chuyển (nếu có)" v-model="form.transport_name">
                        <el-option v-for="(item, index) in transporters" :key="index" :label="item" :value="item"></el-option>
                      </el-select>
                    </div>
                    <div class="col-12 col-md-3">
                      <el-input
                        placeholder="Mã vận đơn (nếu có)"
                        v-model="form.shipping_code">
                      </el-input>
                    </div>
                  </div>

                </td>
              </tr>

              <tr>
                <td class="d-none d-md-block">Tình trạng</td>
                <td>

                  <div class="item-checkbox-status">
                    <el-switch v-model="order.is_packed" active-color="#13ce66"></el-switch>
                    <span>Đã đóng gói</span>
                    <span class="red" v-if="order.is_packed && order.packed_at">{{ $util.dateTimeFormat(order.packed_at) }}</span>
                  </div>

                  <div class="item-checkbox-status">
                    <el-switch v-model="order.is_engraved" active-color="#13ce66"></el-switch>
                    <span>Đã khắc tên</span>
                    <span class="red" v-if="order.is_engraved && order.engraved_at">{{ $util.dateTimeFormat(order.engraved_at) }}</span>
                  </div>

                  <div class="item-checkbox-status">
                    <el-switch v-model="order.is_shipped" active-color="#13ce66" disabled></el-switch>
                    <span>Đã giao cho đơn vị vận chuyển</span>
                    <span class="red" v-if="order.is_shipped">{{ $util.dateTimeFormat(this.order.meta.transport.shipped_at) }}</span>
                  </div>

                </td>
              </tr>

              <tr>
                <td class="d-none d-md-block">Ghi chú</td>
                <td>
                  <el-input v-model="order.notes" type="textarea" placeholder="Ghi chú" :rows="3"></el-input>
                </td>
              </tr>

              <tr>
                <td class="d-none d-md-block"></td>
                <td>
                  <el-button type="primary" @click="updateOrderStatus">Cập nhật</el-button>
                </td>
              </tr>

            </table>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="Import" :visible.sync="is_show_modal_import_file" width="70%">
      <div class="row">
        <div class="col-12">
          <div><el-radio v-model="import_type" label="gift">Danh sách quà tặng</el-radio></div>
          <div><el-radio v-model="import_type" label="engraved">Danh sách đã khắc tên</el-radio></div>
          <div><el-radio v-model="import_type" label="shipping_status_store">Trạng thái giao hàng - tại cửa hàng (nhận tại cửa hàng, đã giao cho đơn vị vận chuyển, nhận tại cửa hàng đối tác)</el-radio></div>
          <div><el-radio v-model="import_type" label="shipping_status_transporter">Trạng thái giao hàng - đơn vị vận chuyển (các trạng thái giao hàng, trả hàng, thất lạc)</el-radio></div>
        </div>
        <div class="col-12 text-center" style="margin-top: 20px;">
          <el-upload
            class="upload-demo"
            drag
            action=""
            :on-change="handleUploadChange"
            :on-remove="handleRemoveUpload"
            accept=".xlsx,.xls"
            :auto-upload="false"
            :multiple="false"
            :file-list="list_files">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Chọn file</div>
            <div class="el-upload__tip" slot="tip">Hỗ trợ file xls, xlsx</div>
          </el-upload>
          <div>
            <a :href="list_templates[import_type]" target="_blank">Tải file mẫu</a>
          </div>
          <div class="import-status" v-if="is_show_import_status">
            <el-tag type="primary">Tổng: {{ import_status.total }}</el-tag>
            <el-tag type="success">Thành công: {{ import_status.success }}</el-tag>
            <el-tag type="danger">Thất bại: {{ import_status.total - import_status.success ? import_status.total - import_status.success : 0 }}</el-tag>
            <div style="margin-top: 10px;">
              <el-tag v-if="import_status.errors.length" v-for="(item, index) of import_status.errors" :key="index" type="danger" size="small">{{ item }}</el-tag>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="sm" @click="closeModalImport">Hủy</el-button>
        <el-button size="sm" type="primary" @click="submitUpload">Import</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Export" :visible.sync="is_show_modal_export_file" width="50%">
      <div><el-radio v-model="export_type" label="shipping_all">Thông tin đóng gói + vận chuyển (Tất cả)</el-radio></div>
      <div><el-radio v-model="export_type" label="shipping_completed">Thông tin đóng gói + vận chuyển (Có phí & hoàn thành)</el-radio></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="is_show_modal_export_file = false">Hủy</el-button>
        <el-button type="primary" @click="exportData">Export</el-button>
      </span>
    </el-dialog>

    <loading :active.sync="is_loading"></loading>

  </div>
</template>

<script>
  import { Message, MessageBox, Button, Input, Table, TableColumn, Select, Option, Tag, Switch, Autocomplete, Dialog, Radio, Upload, DatePicker } from 'element-ui';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    components: {
      Message,
      Loading,
      ElTag: Tag,
      ElButton: Button,
      ElInput: Input,
      ElTable: Table,
      ElTableColumn: TableColumn,
      ElSelect: Select,
      ElOption: Option,
      ElSwitch: Switch,
      ElDialog: Dialog,
      ElRadio: Radio,
      ElUpload: Upload,
      ElAutocomplete: Autocomplete,
      ElDatePicker: DatePicker
    },

    beforeCreate() {
      this.$store.dispatch('fetchRacesShort');
      this.$store.dispatch('fetchShippingStatus');
      this.$store.dispatch('getShippingConfig');
    },

    data() {
      return {
        form: {
          race_id: "",
          shipping_status: '',
          shipping_at: '',
          transport_name: '',
          shipping_code: ''
        },
        order: {
          addons: [],
          is_packed: false,
          is_engraved: false,
          is_shipped: false
        },
        links: [],
        race_selected: '',
        export_type: 'shipping_all',
        import_type: 'gift',
        is_loading: false,
        is_show_modal_import_file: false,
        is_show_modal_export_file: false,
        is_show_import_status: false,
        list_files: [],
        import_status: {
          total: 0,
          success: 0,
          errors: []
        }
      }
    },

    computed: {
      races() {
        let data = this.$store.state.shortRaces;
        return data;
      },

      shipping_status() {
        let data = this.$store.state.shippingConfig.status;
        return data;
      },

      transporters() {
        let data = this.$store.state.shippingConfig.transporter;
        return data;
      },


      list_templates() {
        return {
          gift: 'https://irace.vn/races/files/import_gift_template.xlsx',
          engraved: 'https://irace.vn/races/files/import_engraved_template.xlsx',
          shipping_status_store: 'https://irace.vn/races/files/import_shipping_status_store_template.xlsx',
          shipping_status_transporter: 'https://irace.vn/races/files/import_shipping_status_transporter_template.xlsx',
        };
      }
    },

    methods: {

      closeModalImport() {
        this.is_show_modal_import_file = false;
        this.is_show_import_status = false;
        this.list_files = [];
      },

      handleUploadChange(file, list_files) {
        this.list_files = list_files.slice(-1);
      },

      handleRemoveUpload(file, list_files) {
        this.list_files = [];
      },

      async submitUpload() {
        this.is_show_import_status = false;
        this.import_status.success = 0;
        this.import_status.total = 0;
        this.import_status.errors = [];

        if (this.list_files.length == 0) {
          return Message({
            type: 'error',
            message: 'Vui lòng chọn file'
          });
        }

        this.is_loading = true;
        const formData = new FormData();
        formData.append('file_import', this.list_files[0].raw);
        formData.append('type', this.import_type);
        await this.$store.dispatch('importOperationShip', formData).then(res => {
          let data = res.data;
          this.is_show_import_status = true;
          this.import_status.total = data.total;
          this.import_status.success = data.save;
          this.import_status.errors = data.errors;
        });
        this.is_loading = false;
      },

      querySearch(queryString, cb) {
        var races = this.races;
        if (!queryString) {
          return cb(races);
        }

        let results = races.filter(this.createFilter(queryString));
        cb(results);
      },

      createFilter(queryString) {
        return (link) => {
          return (link.title.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },

      handleSelect(item) {
        this.form.race_id = item.id;
        this.race_selected = item.id + '. ' + item.title;
      },

      showModalImport() {
        this.is_show_modal_import_file = true;
      },

      showModalExport() {
        if (!this.form.race_id) {
          return Message({
            type: 'error',
            message: 'Chưa chọn sự kiện'
          });
        }
        this.is_show_modal_export_file = true;
      },

      async exportData() {

        let obj = {
          race_id: this.form.race_id,
          type: this.export_type
        }

        this.is_loading = true;

        await this.$store.dispatch('exportOperationShip', obj).then(res => {
          location.href = res.data.url;
        }, error => {
          Message({
            message: error,
            type: 'error'
          });
        });

        this.is_loading = false;

      },

      getUserOrder() {
        if (!this.form.user_id) {
          return Message({
            type: 'error',
            message: 'Chưa nhập User ID'
          });
        }

        if (!this.form.race_id) {
          return Message({
            type: 'error',
            message: 'Chưa chọn sự kiện'
          });
        }

        let obj = {
          user_id: this.form.user_id,
          race_id: this.form.race_id,
          status: 1
        };

        this.resetObject();
        this.is_loading = true;

        this.$store.dispatch('getUserOrder', obj).then(res => {
          this.is_loading = false;
          if (!res.data.length) {
            Message({
              type: 'error',
              message: 'Không tìm thấy đơn hàng'
            });
          } else {
            this.order = Object.assign({}, this.order, res.data[0]);
            if (this.order.meta) {
              this.form.shipping_status = this.order.meta.shipping_status;
              this.form.shipping_at = this.order.meta.shipping_at;
              this.form.transport_name = this.order.meta.transport_name;
              this.form.shipping_code = this.order.meta.shipping_code;
              if (this.order.meta.transport && this.order.meta.transport.shipped_at) {
                this.order.is_shipped = true;
              }
            }
            this.showBtnSendEmail();
          }
        });
      },

      resetObject() {
        this.form.shipping_status = '';
        this.form.shipping_at = '';
        this.form.transport_name = '';
        this.form.shipping_code = '';

        this.order.addons = [];
        this.order.is_packed = false;
        this.order.is_engraved = false;
        this.order.is_shipped = false;
      },

      async updateOrderStatus() {
        this.is_loading = true;
        let obj = {
          shipping_status: this.form.shipping_status,
          shipping_at: this.form.shipping_at,
          transport_name: this.form.transport_name,
          shipping_code: this.form.shipping_code,
          order_line_id: this.order.id,
          is_packed: this.order.is_packed,
          is_engraved: this.order.is_engraved,
          notes: this.order.notes
        };

        await this.$store.dispatch('updateShippingOrderLine', obj).then(res => {
          Message({type: 'success', message: 'Cập nhật thành công'});
          this.order.packed_at = res.data.packed_at;
          this.order.engraved_at = res.data.engraved_at;
          this.showBtnSendEmail();
        }, error => {
          Message({message: error, type: 'error'});
        });
        this.is_loading = false;
      },

      showBtnSendEmail() {
        let arr = [{
            label: 'Import',
            type: 'primary',
            icon: 'el-icon-upload2',
            callback: this.showModalImport
          },
          {
            label: 'Export',
            type: 'info',
            icon: 'el-icon-download',
            callback: this.showModalExport
          }
        ];

        if (this.form.shipping_status) {
          arr.push({
            label: 'Gửi email',
            type: 'warning',
            icon: 'el-icon-position',
            callback: this.sendEmail
          });
        }

        this.$store.dispatch('setCurrentActions', arr);
      },

      async sendEmail() {

        if (!this.form.shipping_status) {
          return Message({
            type: 'error',
            message: 'Chưa cập nhật đơn hàng'
          });
        }

        MessageBox.confirm('Gửi email cho ' + this.order.name + '?', 'Warning', {
          confirmButtonText: 'Đồng ý',
          cancelButtonText: 'Hủy bỏ',
          type: 'warning',
          center: true
        }).then(async item => {
          this.is_loading = true;
          let order_line_id = this.order.id;
          let data = {
            order_line_id: order_line_id
          };
          await this.$store.dispatch('sendEmailUserShipping', data).then(res => {
            return Message({
              type: 'success',
              message: 'Hệ thống đang xử lý'
            });
          });

          this.is_loading = false;
        }, error => {

        });
      }

    },

    mounted() {
      window.AP = this;
      this.$store.dispatch('setPageTitle', 'Operation & Ship');
      this.$store.dispatch('setCurrentFilterUsers', []);
      this.$store.dispatch('setCurrentActions', [{
        label: 'Import',
        type: 'primary',
        icon: 'el-icon-upload2',
        callback: this.showModalImport
      }, {
        label: 'Export',
        type: 'info',
        icon: 'el-icon-download',
        callback: this.showModalExport
      }]);
    },

    destroyed() {
      this.$store.dispatch('setCurrentActions', []);
    }
  }

</script>
<style lang="scss" scoped>

  .el-autocomplete-suggestion li {
    white-space: initial;
  }

  .el-table .td-actions {
    button.btn {
      margin-right: 5px;
    }
  }

  .filter-group-bill {
    padding: 15px;
  }

  .import-status {
    width: 60%;
    margin: 20px auto 0;
  }

  .user-info {
    padding: 20px 0;
    div {
      margin-bottom: 5px;
    }
  }

  .vip {
    color: red;
    font-size: 20px;
  }

  .pro {
    color: blue;
    font-size: 20px;
  }

  @media screen and (max-width: 1024px) {
    .table-content {
      tr > td:first-child {
        min-width: 140px;
      }
    }
  }

  @media screen and (min-width: 767px) {
    table td {
      display: table-cell !important;
    }
  }

  @media screen and (max-width: 767px) {
    .search-box {
      .col-12 {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .shipping {
      .col-12 {
        margin-bottom: 10px;
      }
    }

  }

  .title-mobile {
    text-decoration: underline;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .item-checkbox-status {
    margin-bottom: 10px;
    span {
      display: inline-block;
      margin-left: 10px;
    }
  }

</style>
